var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4 font-Noto-Sans job-list-page"},[_c('v-sheet',{attrs:{"color":"transparent"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"\n            fw-500\n            heading-text\n            d-flex\n            justify-space-between\n            flex-wrap\n            align-center\n            mt-5\n            mb-2\n          "},[_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('h2',{staticClass:"font-24px mb-1 mr-7"},[_c('span',{staticClass:"fw-500"},[_vm._v("チャット")]),_c('span',{staticClass:"line"}),_c('span',{staticClass:"font-20px fw-400"},[_vm._v("チャット一覧")])])]),_c('div',{staticClass:"d-flex font-14px align-center"},[_c('v-btn',{staticClass:"white text-red border-red",attrs:{"min-width":"188px"},on:{"click":function($event){_vm.toggleSearch = !_vm.toggleSearch
                _vm.setChangedInputType('search')}}},[_vm._v("詳細条件検索")])],1)])]),(_vm.toggleSearch)?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('SearchArea',{attrs:{"toggleSearch":_vm.toggleSearch,"searchFields":_vm.searchFields,"selectTypeOptions":_vm.selectTypeOptions},on:{"toggleSearch":function($event){return _vm.setToggleSearch($event)},"searchSubmit":function($event){return _vm.searchSubmit($event)},"changedInputType":function($event){return _vm.setChangedInputType($event)},"resetForm":function($event){return _vm.getDataFromApi()}}})],1):_vm._e(),_c('v-col',{staticClass:"d-flex w-100",attrs:{"cols":"12","md":"12"}},[_c('DataTable',{staticClass:"table-job-list",attrs:{"items":_vm.getAllChats,"headers":_vm.headers,"total-records":_vm.getChatTotalRecord ? _vm.getChatTotalRecord : 0,"number-of-pages":_vm.getChatTotalPages ? _vm.getChatTotalPages : 0,"loading":_vm.loading},on:{"update:options":_vm.updateTable,"click:row":function($event){return _vm.$router.push({
              name: 'ChatDetails',
              params: {
                id: $event.id
              }
            })}},scopedSlots:_vm._u([{key:"item.job_title",fn:function(ref){
            var item = ref.item;
return [(item.job_title)?[_c('span',{staticClass:"truncate-lines lines-1"},[_vm._v(" "+_vm._s(item.job_title.ja)+" ")])]:_vm._e()]}},{key:"item.latest_message",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"truncate-lines lines-1"},[_vm._v(" "+_vm._s(item.latest_message)+" ")])]}},{key:"item.job_application_status",fn:function(ref){
            var item = ref.item;
return [(item.job_application_status)?_c('v-btn',{staticClass:"font-12px fw-500 rounded-xl",class:[
                item.job_application_status.en == 'Completed' ||
                item.job_application_status.en == 'Reject' ||
                item.job_application_status.en == 'Canceled' ||
                item.job_application_status.en == 'No show'
                  ? 'white--text'
                  : ''
              ],attrs:{"color":_vm.getStatusColors(item),"width":"72","height":"22","elevation":"0"}},[_vm._v(" "+_vm._s(item.job_application_status.en == 'Reject' ? '不採用' : item.job_application_status.ja)+" ")]):_vm._e()]}},{key:"item.is_favorite",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.setFaviroteChat(item)}}},[(item.is_favorite == 1)?[_c('v-icon',{staticStyle:{"--inner":"#fff7c0","--outer":"#b8b8b8"},attrs:{"size":"20"}},[_vm._v(" $Star ")])]:[_c('v-icon',{staticStyle:{"--inner":"#fff","--outer":"#b8b8b8"},attrs:{"size":"20"}},[_vm._v(" $Star ")])]],2)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }